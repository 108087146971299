import calcTotalCostOfOwnership from "./calcTotalCostOfOwnership"
// IMPORT ASSUMPTIONS HERE AND TOGGLE HERE
import { SALES_TAX, ELECTRICITY_RATE_IN_DOLLARS_PER_KWH } from "../../../client_customizations/data/assumptions/ASSUMPTIONS"


const getCarCostOfOwnership = (vehicle, userPrefs, insuranceData, maintenanceData) => {
    return calcTotalCostOfOwnership(
        vehicle,
        userPrefs.get("purchaseMethod"),
        userPrefs.get("monthsOfOwnership"),
        userPrefs.get("milesDrivenAnnually"),
        userPrefs.get("interestRateAsBasisPoints"),
        userPrefs.get("electricMilesPortionForPhev"),
        userPrefs.get("gasolinePriceInCentsPerGal"),
        userPrefs.get("includeResaleValue"),
        process.env.REACT_APP_SLIDE_SALES_TAX ? userPrefs.get("salesTax") : SALES_TAX.value,
        process.env.REACT_APP_SLIDE_COST_PUBLIC_CHARGING ? userPrefs.get("costPublicCharging") : 0,
        process.env.REACT_APP_SLIDE_PORTION_PUBLIC_CHARGING ? userPrefs.get("publicChargingPercentage") : 0,
        process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE ? userPrefs.get("electricityRate") : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.value,
        process.env.REACT_APP_DYNAMIC_INSURANCE_COST ? userPrefs.get("municipality") : null,
        insuranceData,
        maintenanceData
      ) 
}

export default getCarCostOfOwnership
