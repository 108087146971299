import React from "react";
import PropTypes from "prop-types";

import ChargingPinPublic from "./../../../client_customizations/assets/images/icons/charger-pin-public.png";
import ChargingPinHighPower from "./../../../client_customizations/assets/images/icons/charger-pin-high-power.png";
import ChargingPinDefault from "./../../../client_customizations/assets/images/icons/charger-pin-private.png";
import isSuperChargingStation from '../../../utils/predicates/isSuperChargingStation';
import { FormattedMessage } from "react-intl"

import "./ChargingStationsMapLegend.scss";


const ChargingStationsMapLegend = ({ chargingStations }) => {
  const hasSuperChargerStations =
    (chargingStations || []).some(station => isSuperChargingStation(station));

  return (
    <div className="legend ChargingStationsMapLegend">
      <div className="charger-type">
        <b>
          <FormattedMessage
            id="chargingStations.mapLegend.public"
            defaultMessage="Public Stations"
            description="Public Stations Title"
          />
        </b>
        <br />
        <span>
          <FormattedMessage
            id="chargingStations.mapLegend.publicSubtext"
            defaultMessage="Installed by business or government"
            description="Public Stations subtitle"
          />
        </span>
        <img src={ChargingPinPublic} alt="" />
      </div>
      {hasSuperChargerStations && (
        <div className="charger-type">
          <b>
            <FormattedMessage
              id="chargingStations.mapLegend.high"
              defaultMessage="High Power Stations"
              description="High Power Stations Title"
            />
          </b>
          <br />
          <span>
            <FormattedMessage
              id="chargingStations.mapLegend.highSubtext"
              defaultMessage="DC fast charge or superchargers"
              description="High Stations subtitle"
            />
          </span>
          <img src={ChargingPinHighPower} alt="" />
        </div>
      )}
      <div className="charger-type">
        <b>
          <FormattedMessage
            id="chargingStations.mapLegend.other"
            defaultMessage="Other Types of Stations"
            description="Other Types of Stations Title"
          />
        </b>
        <br />
        <span>
          <FormattedMessage
            id="chargingStations.mapLegend.private"
            defaultMessage="Private Stations"
            description="Private Stations Title"
          />
        </span>
        <img src={ChargingPinDefault} alt="" />
      </div>
    </div>
  );
};

ChargingStationsMapLegend.propTypes = {
  chargingStations: PropTypes.array
};

ChargingStationsMapLegend.defaultProps = {
  chargingStations: []
};

export default ChargingStationsMapLegend;
