import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import InputHeader from "../../../components/InputHeader/InputHeader"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import logo from "../../../assets/images/Platte_main.svg"
import "./Header.scss";
import {FormattedMessage} from 'react-intl';

const Header = ({ page, userLocation, language, changeLanguage }) => {
  const [collapse, setCollapse] = useState(false);
  const isHomepage =
    window.location.pathname === "/" || window.location.pathname === null;

    const isMobile = (window.innerWidth < 426)
  return (
    <div className="Header">
      <div className="container" style={{ padding: 0, marginBotton: "-15px" }}>
        <div className="communitySection">
          <FormattedMessage
            id="select.community"
            defaultMessage="Select your community"
            description="Community Header"
          />{" "}
          &nbsp;
          {isMobile ? <br /> : null}
          {isMobile ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <InputHeader userLocation={userLocation} />
            </div>
          ) : (
            <InputHeader userLocation={userLocation} />
          )}
          <div className="language-filter">
            <UncontrolledDropdown inNavbar>
              <DropdownToggle nav caret className="active">
                <span>{language === "EN" ? "English" : "Español"}</span>
              </DropdownToggle>
              <DropdownMenu className="text-center">
                <DropdownItem
                  role="menuitem"
                  key={`nav-item-language-english`}
                  title={language === "EN" ? "Active Language" : null}
                  tag={NavLink}
                  to={"#"}
                  aria-label="Change to English Language"
                  onClick={() => changeLanguage("EN")}
                >
                  English
                </DropdownItem>
                <DropdownItem
                  role="menuitem"
                  key={`nav-item-language-spanish`}
                  title={language === "ES" ? "Active Language" : null}
                  tag={NavLink}
                  to={"#"}
                  aria-label="Change to Spanish Language"
                  onClick={() => changeLanguage("ES")}
                >
                  Español
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        <Navbar expand="lg">
          <div style={{ paddingBottom: "0.75rem" }}>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://efficiencyworks.org`}
            >
              <img
                src={logo}
                alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                className="img-fluid header-logo"
              />
            </a>
          </div>

          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto" navbar>
              {process.env.REACT_APP_PAGES_HOME_ENABLED ? (
                <NavItem>
                  <Link to="/" className={isHomepage ? "active" : ""}>
                    <span>
                      <FormattedMessage
                        id="header.home"
                        defaultMessage="Home"
                        description="Home Header"
                      />
                    </span>
                  </Link>
                </NavItem>
              ) : null}

              {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "vehicles" ? "Active Page" : null}
                    to="/vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="header.electricVehicles"
                        defaultMessage="Electric Vehicles"
                        description="Electric Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "compare" ? "Active Page" : null}
                    to="/compare-vehicles"
                  >
                    <span>
                      <FormattedMessage
                        id="header.compareVehicles"
                        defaultMessage="Compare Vehicles"
                        description="Compare Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "incentives" ? "Active Page" : null}
                    to="/incentives"
                  >
                    <span>
                      <FormattedMessage
                        id="header.incentives"
                        defaultMessage="Incentives"
                        description="Incentives Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
              {process.env.REACT_APP_PAGES_LEARN_MORE_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "learn-more" ? "Active Page" : null}
                    to="/learn-more"
                  >
                    <span>
                      <FormattedMessage
                        id="header.learnMore"
                        defaultMessage="Learn More"
                        description="Learn More Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : null}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string
};
