import USER_PREF_PRESETS_CLIENT_CUSTOM from "../../client_customizations/context/UserPrefs/USER_PREF_PRESETS_CLIENT_CUSTOM"

const USER_PREF_PRESETS = {
  canTurnInClunker: "false",
  chargerInstallAllowed: null,
  chargerLevel: "level_2",
  electricMilesPortionForPhev: 60,
  hasOvernightCharger: null,
  electricityProvider: "All",
  gasolinePriceInCentsPerGal: 295,
  hasRegularPowerOutlet: null,
  householdIncome: 75000,
  householdSize: 1,
  includeResaleValue: true,
  interestRateAsBasisPoints: 350,
  locallyAvailableVehicleFilter: false,
  maxBudget: 110000,
  milesDrivenAnnually: 20000,
  milesDrivenDaily: 300,
  minSeats: 2,
  monthsOfOwnership: 60,
  parkingLocation: "",
  purchaseMethod: "cash",
  showAllRates: false,
  showGasolineCost: true,
  showHomeElectricityCost: true,
  showRateDetails: true,
  showDetailsForRate: "EV2-A",
  taxFilingStatus: "single",
  vehicleChargingPattern: "After midnight; Before 3pm",
  vehicleIdForIncentives: "",
  vehicleHandleForIncentives: "",
  vehiclesForIncentives: [],
  vehicleInsuranceYearly: null,
  vehicleSortDirection: "desc",
  incentiveSortDirection: "desc",
  incentiveSortType: "price",
  vehicleSortType: "electric_range",
  workingZipcode: `${process.env.REACT_APP_DEFAULTS_POSTAL_CODE}`,
  zipcode: `${process.env.REACT_APP_DEFAULTS_POSTAL_CODE}`,
  chargerBudget: 2000,
  chargerCordLength: 25,
  chargerSortType: "price",
  chargerSortDirection: "asc",
  vehicleMakeFilter: "All",
  vehicleModelFilter: "",
  vehicleTrimFilter: "",
  eventDistance: "25",
  eventLocation: "In Person",
  eventsSortType: "date-asc",
  salesTax: '0.0725',
  costPublicCharging: "0.2",
  publicChargingPercentage: "20",
  electricityRate: ".16611",
  municipality: "",
  country: "United States" || null,

  vehicleFuelTypeFilters: {
    bev: false,
    phev: false,
  },

  selectLocations: {
    "Estes Park": 80517,
    "Fort Collins": 80522,
    "Longmont": 80501,
    "Loveland": 80537,
  },
  selectSalesTaxElecRate: {
    80517: ["0.087", '.1217', "https://estespark.colorado.gov/salestax"],
    80522: ["0.0755", '0.0999', "https://www.sale-tax.com/FortCollinsCO"],
    80501: ["0.08515", '0.1', "https://www.sale-tax.com/LongmontCO"],
    80537: ["0.067", '0.1015', "https://www.sale-tax.com/LovelandCO"],
  },

  vehicleFormFactorFilters: {
    sedan: false,
    hatchback: false,
    coupe: false,
    crossover: false,
    minivan: false,
    suv: false,
    wagon: false,
    truck: false,
  },

  chargerWifiFilter: {
    yes: false,
    no: false,
  },

  chargerTypeFilters: {
    mounted: false,
    portable: false,
  },

  chargerFormFactorFilters: {
    "6-20": false,
    "6-30": false,
    "10-30": false,
    "14-30": false,
    "6-50": false,
    "14-50": false,
    "14-60": false,
    Hardwired: false,
  },
};

export default {...USER_PREF_PRESETS, ...USER_PREF_PRESETS_CLIENT_CUSTOM};
