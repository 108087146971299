import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";

import upArrow from "../../client_customizations/assets/images/icons/up-arrow.svg";
import downArrow from "../../client_customizations/assets/images/icons/down-arrow.svg";
import banner from "../../client_customizations/assets/images/faq_banner.jpg";
import topImage from "../../client_customizations/assets/images/faq-topView.jpg";
import middleImage from "../../client_customizations/assets/images/faq-middleView.svg";
import bottomImage from "../../client_customizations/assets/images/faq-bottomView.svg";
import levelOneChargerImg from "../../client_customizations/assets/images/level-1-charger-column.png";
import levelTwoChargerImg from "../../client_customizations/assets/images/level-2-charger-column.png";
import levelThreeChargerImg from "../../client_customizations/assets/images/level-3-charger-column.png";
import levelOneChargerImgEsp from "../../client_customizations/assets/images/Level1espanol.svg";
import levelTwoChargerImgEsp from "../../client_customizations/assets/images/Level2espanol.svg";
import levelThreeChargerImgEsp from "../../client_customizations/assets/images/Level3espanol.svg";
import { FormattedMessage } from "react-intl";

import "./FAQ.scss";

const FAQ = ({ ip, uuid, userLocation,language }) => {
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_FAQ_TITLE;
  });
  const [state, setState] = useState({
    benefitsTab: false,
    lifetimeTab: false,
    howTab: false,
    whereBuy: false,
    differentTab: false,
    differenceTab: false,
    doINeed: false,
    chargerTab: false,
    timeTab: false,
    stationTab: false,
    safeTab: false,
  });
  const toggleTab = (tab) => {
    setState({ ...state, [tab]: !state[tab] });
  };

  const btnOpts = [
    {
      text: (
        <FormattedMessage
          id="location.estesPark"
          defaultMessage="Estes Park"
          description="Estes Park Option"
        />
      ),
      link: "https://estespark.colorado.gov/electricvehicles",
    },
    {
      text: (
        <FormattedMessage
          id="location.fortCollins"
          defaultMessage="Fort Collins"
          description="Fort Collins Option"
        />
      ),
      link: "https://www.fcgov.com/utilities/residential/conserve/EVs",
    },
    {
      text: (
        <FormattedMessage
          id="location.longmont"
          defaultMessage="Longmont"
          description="Longmont Option"
        />
      ),
      link: "https://www.longmontcolorado.gov/departments/departments-e-m/longmont-power-communications/electric-service/electric-vehicle-charging-stations",
    },
    {
      text: (
        <FormattedMessage
          id="location.loveland"
          defaultMessage="Loveland"
          description="Loveland Option"
        />
      ),
      link: "https://www.lovelandwaterandpower.org/city-government/departments/water-and-power/i-am-a-resident/efficiency-programs-and-rebates/residential-energy-efficiency-programs-and-rebates/electric-vehicles",
    },
  ];

  const renderBtns = useMemo(() => {
    return btnOpts.map((btn) => {
      return (
          <a
            style={{ color: "white" }}
            href={btn.link}
            target="_blank"
            rel="noopener noreferrer"
            className="btn"
          >
            {" "}
            {btn.text}{" "}
          </a>
      );
    });
  }, [btnOpts]);

  return (
    <section id="FAQ" className="p-0 ev-container-fluid grey">
      <div className="big-promise" style={{ position: "relative" }}>
        <div className="gradient-top-left" />
        <div
          className="img-container"
          style={{
            backgroundImage: "url(" + banner + ")",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            WebkitBackgroundSize: "cover",
            MozBackgroundSize: "cover",
            OBackgroundSize: "cover",
            backgroundSize: "cover",
            padding: 0,
            textAlign: "center",
            height: "575px",
            boxSizing: "border-box",
          }}
        />
        <div className="container big-promise-text">
          <div>
            <h1>
              <FormattedMessage
                id="learnMore.heroTitle"
                defaultMessage="Electric vehicles 101"
                description="heroImage Text"
              />
            </h1>
          </div>
        </div>
      </div>
      <section className="sideBySide">
        <div className="row container">
          <div className="col text left">
            <h1 className="text-title">
              <FormattedMessage
                id="learnMore.questions"
                defaultMessage="Your questions answered"
                description="sideBySide first item title"
              />
            </h1>
            <div className="top small-space">
              <p>
                <FormattedMessage
                  id="learnMore.questionsTop"
                  defaultMessage="Electric vehicles are becoming more economical to own and
                operate, plus they use locally generated energy resources,
                reduce your carbon footprint and contribute to cleaner air."
                  description="sideBySide first content top"
                />
              </p>
            </div>
            <div className="bottom large-space">
              <p>
                <FormattedMessage
                  id="learnMore.questionsBottom"
                  defaultMessage="The member utilities of the Efficiency Works program
                collaborated to provide customers a resource to learn more about
                electric vehicles. Compare costs of electric and gas-powered
                vehicles, discover electric vehicle models and estimate
                potential incentives for purchasing an electric vehicle. For
                more information on how electric vehicles are helping to power a
                cleaner future for northern Colorado, email {information}"
                  description="sideBySide first content bottom"
                  values={{
                    information: (
                      <a
                        href={`mailto:${`information@EfficiencyWorks.org`}`}
                        target="_top"
                        style={{textDecoration: 'underline'}}
                      >
                        {" "}
                        information@EfficiencyWorks.org.
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
          <div className="col image right">
            <img src={topImage} alt="" />
          </div>
        </div>
      </section>
      <section className="sideBySide reverse" id="">
        <div className="row container">
          <div className="col image left">
            <img src={middleImage} alt="" />
          </div>
          <div className="col text right">
            <h1>
              <FormattedMessage
                id="learnMore.chargingSimple"
                defaultMessage="Charging is simple and easy"
                description="sideBySide second item title"
              />
            </h1>
            <div className="top small-space">
              <p>
                <FormattedMessage
                  id="learnMore.chargingSimpleTop"
                  defaultMessage="Charging can be as easy as plugging in to your standard wall
                outlet with a Level 1 home charger. For a faster recharge
                experience installing a powerful Level 2 home charger is the
                best way to keep your EV fully charged and prepared for the
                adventure."
                  description="sideBySide second content top"
                />
              </p>
            </div>
            <div className="middle small-space">
              <p>
                <FormattedMessage
                  id="learnMore.chargingSimpleMiddle"
                  defaultMessage=" For proper Level 2 Home charger installation, connect with a
                professional electrician to complete your installation, and
                you'll be ready to plug in and charge."
                  description="sideBySide second content middle"
                />
              </p>
            </div>
            <div className="bottom small-space">
              <p>
                <FormattedMessage
                  id="learnMore.chargingSimpleBottom"
                  defaultMessage="And with new charging stations opening every day, it's never
                been easier to charge on the go! Find charging stations by using
                the {chargeHub} or {plugShare} locator maps."
                  description="sideBySide second content bottom"
                  values={{
                    chargeHub: (
                      <a
                        className="link"
                        href="https://chargehub.com/en/charging-stations-map.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{textDecoration: 'underline'}}
                      >
                        ChargeHub
                      </a>
                    ),
                    plugShare: (
                      <a
                        className="link"
                        href="https://www.plugshare.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{textDecoration: 'underline'}}
                      >
                        PlugShare
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="row container flex-row-center">
        <div className="col text-center">
          <h5 className="frequently">
            <FormattedMessage
              id="learnMore.faqs"
              defaultMessage="CHARGING FAQS"
              description="questions title"
            />
          </h5>
        </div>
      </div>
      <section
        id="learnScroll"
        className={
          state.differentTab
            ? " container tab-section shadowed active"
            : " container tab-section shadowed"
        }
      >
        <div className="tab-header">
          <div
            className="container pointer"
            onClick={() => toggleTab("differentTab")}
          >
            <h1>
              <FormattedMessage
                id="learnMore.areThere"
                defaultMessage="Are there different types of chargers?"
                description="areThere title"
              />
            </h1>
            {state.differentTab ? (
              <div className="toggle-button">
                <img src={upArrow} alt="Up" />
              </div>
            ) : (
              <div className="toggle-button">
                <img src={downArrow} alt="Down" />
              </div>
            )}
          </div>
        </div>
        <div className="tab-content">
          <FormattedMessage
            id="learnMore.areThereTitle"
            defaultMessage="Yes! Chargers are either Level 1, Level 2, or DC Fast Chargers (Level 3)."
            description="areThere content title"
          />
          {language === "EN" ? (
            <div className="chargers-image-columns">
              <img src={levelOneChargerImg} alt="Level 1 Charger" />
              <img src={levelTwoChargerImg} alt="Level 2 Charger" />
              <img src={levelThreeChargerImg} alt="Level 3 Charger" />
            </div>
          ) : (
            <div className="chargers-image-columns">
              <img src={levelOneChargerImgEsp} alt="Level 1 Charger Spanish" />
              <img style={{marginTop: "2.5rem"}}src={levelTwoChargerImgEsp} alt="Level 2 Charger Spanish" />
              <img src={levelThreeChargerImgEsp} alt="Level 3 Charger Spanish" />
            </div>
          )}
        </div>
      </section>
      <section
        id="learnScroll"
        className={
          state.whereBuy
            ? " container tab-section shadowed active"
            : " container tab-section shadowed"
        }
      >
        <div className="tab-header">
          <div
            className="container pointer"
            onClick={() => toggleTab("whereBuy")}
          >
            <h1>
              <FormattedMessage
                id="learnMore.where"
                defaultMessage="Where can I buy a charger?"
                description="Where Title"
              />
            </h1>
            {state.whereBuy ? (
              <div className="toggle-button">
                <img src={upArrow} alt="Up" />
              </div>
            ) : (
              <div className="toggle-button">
                <img src={downArrow} alt="Down" />
              </div>
            )}
          </div>
        </div>
        <div className="tab-content">
          <p>
            <FormattedMessage
              id="learnMore.whereOne"
              defaultMessage="While all EV's come ready to plug into a Level 1 wall outlet, for
            faster charging it is recommended to install a Level 2 home charger."
              description="Where Content Top"
            />
          </p>
          <p>
            <FormattedMessage
              id="learnMore.whereTwo"
              defaultMessage="Chargers can be purchased from the auto manufacturer, from charger manufacturer websites or third party resellers online and in-store. Level 2 home chargers and required 240V circuits should be installed by a professional electrician."
              description="Where ContentBottom"
            />
          </p>
        </div>
      </section>
      <section
        id="learnScroll"
        className={
          state.doINeed
            ? " container tab-section shadowed active"
            : " container tab-section shadowed"
        }
      >
        <div className="tab-header">
          <div
            className="container pointer"
            onClick={() => toggleTab("doINeed")}
          >
            <h1>
              <FormattedMessage
                id="learnMore.doI"
                defaultMessage="Do I need to install special charging equipment at my home?"
                description="doI Title"
              />
            </h1>
            {state.doINeed ? (
              <div className="toggle-button">
                <img src={upArrow} alt="Up" />
              </div>
            ) : (
              <div className="toggle-button">
                <img src={downArrow} alt="Down" />
              </div>
            )}
          </div>
        </div>
        <div className="tab-content">
          <p>
            <FormattedMessage
              id="learnMore.doIOne"
              defaultMessage="While all EVs come with a Level 1 charger ready to plug into a standard wall outlet, we encourage EV owners to install a Wi-Fi network enabled, level 2 charging unit at home for more rapid and programmable charging. Before installing a level 2 charger, please contact your local building and zoning department with your plans to obtain proper permits and inspections."
              description="doI Content Top"
            />
          </p>
          <p>
            <FormattedMessage
              id="learnMore.doITwo"
              defaultMessage="If you cannot install a charger at your home, there are public
            charging stations available. Find charging stations by using the {chargeHub} or {plugShare} 
            locator maps online or via mobile apps."
              description="doI Content Top"
              values={{
                chargeHub: (
                  <a
                    className="link"
                    href="https://chargehub.com/en/charging-stations-map.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ChargeHub
                  </a>
                ),
                plugShare: (
                  <a
                    className="link"
                    href="https://www.plugshare.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    PlugShare
                  </a>
                ),
              }}
            />
          </p>
        </div>
      </section>
      <section
        id="learnScroll"
        className={
          state.timeTab
            ? " container tab-section shadowed active"
            : " container tab-section shadowed"
        }
      >
        <div className="tab-header">
          <div
            className="container pointer"
            onClick={() => toggleTab("timeTab")}
          >
            <h1>
              <FormattedMessage
                id="learnMore.whenIs"
                defaultMessage="When is the best time to charge my EV?"
                description="whenIs Title"
              />
            </h1>
            {state.timeTab ? (
              <div className="toggle-button">
                <img src={upArrow} alt="Up" />
              </div>
            ) : (
              <div className="toggle-button">
                <img src={downArrow} alt="Down" />
              </div>
            )}
          </div>
        </div>
        <div className="tab-content">
          <p>
            <FormattedMessage
              id="learnMore.whenIsOne"
              defaultMessage="Your community may use time-of-use or time-of-day rates. The most
            economical time to charge an EV is during off-peak hours when
            electricity rates are lower."
              description="whenIs Content Top"
            />
          </p>
          <p>
            <FormattedMessage
              id="learnMore.whenIsTwo"
              defaultMessage=" EV owners can choose to charge when there is more noncarbon energy
            on the system to reduce their carbon footprint. Visit Platte River’s
            website for a {realTime} of the energy resources on the grid."
              description="whenIs Content Bottom"
              values={{
                realTime: (
                  <a
                    className="link"
                    href="https://www.prpa.org/energy-production/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    near-real-time view
                  </a>
                ),
              }}
            />
          </p>
        </div>
      </section>
      <section
        id="learnScroll"
        className={
          state.stationTab
            ? " container tab-section shadowed active"
            : " container tab-section shadowed"
        }
      >
        <div className="tab-header">
          <div
            className="container pointer"
            onClick={() => toggleTab("stationTab")}
          >
            <h1>
              <FormattedMessage
                id="learnMore.whereFind"
                defaultMessage="Where can I find a charging station if I cannot charge at home?"
                description="whereFind Title"
              />
            </h1>
            {state.stationTab ? (
              <div className="toggle-button">
                <img src={upArrow} alt="Up" />
              </div>
            ) : (
              <div className="toggle-button">
                <img src={downArrow} alt="Down" />
              </div>
            )}
          </div>
        </div>
        <div className="tab-content">
          <FormattedMessage
            id="learnMore.whereFindOne"
            defaultMessage="Your community has installed public charging stations and many businesses have charging stations. Find charging stations by using the {chargeHub} or {plugShare} locator maps. These services are also offered through a mobile app."
            description="whereFind Title"
            values={{
              chargeHub: (
                <a
                  className="link"
                  href="https://chargehub.com/en/charging-stations-map.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ChargeHub
                </a>
              ),
              plugShare: (
                <a
                  className="link"
                  href="https://www.plugshare.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PlugShare
                </a>
              ),
            }}
          />
        </div>
      </section>
      <section
        id="learnScroll"
        className={
          state.chargerTab
            ? " container tab-section shadowed active"
            : " container tab-section shadowed"
        }
      >
        <div className="tab-header">
          <div
            className="container pointer"
            onClick={() => toggleTab("chargerTab")}
          >
            <h1>
              <FormattedMessage
                id="learnMore.howFar"
                defaultMessage="How far can an EV drive on a single charge?"
                description="howFar Title"
              />
            </h1>
            {state.chargerTab ? (
              <div className="toggle-button">
                <img src={upArrow} alt="Up" />
              </div>
            ) : (
              <div className="toggle-button">
                <img src={downArrow} alt="Down" />
              </div>
            )}
          </div>
        </div>
        <div className="tab-content">
          <p>
            <FormattedMessage
              id="learnMore.howFarOne"
              defaultMessage="A single charge allows the vast majority of all-electric vehicles to drive at least 100-300—more than enough to fuel several round-trip commutes for most people. Public charging stations are also available in many areas for longer trips."
              description="howFar Content"
            />
          </p>
        </div>
      </section>
      <section className="sideBySide">
        <div className="row container">
          <div className="col text left">
            <h1 className="text-title">
              <FormattedMessage
                id="learnMore.going"
                defaultMessage="Going electric saves a lot"
                description="sideBySide first item title"
              />
            </h1>
            <div className="top small-space">
              <p className="bold">
                <FormattedMessage
                  id="learnMore.goingOneTitle"
                  defaultMessage="Fuel Savings"
                  description="going first item title"
                />
              </p>
              <p>
                <FormattedMessage
                  id="learnMore.goingOneContent"
                  defaultMessage="Gasoline can cost 3-4 times as much per mile as electricity, so the more you drive your EV, the more you save."
                  description="going first item content"
                />
              </p>
            </div>
            <div className="middle small-space">
              <p className="bold">
                <FormattedMessage
                  id="learnMore.goingTwoTitle"
                  defaultMessage="Maintenance Savings"
                  description="going second item title"
                />
              </p>
              <p>
                <FormattedMessage
                  id="learnMore.goingTwoContent"
                  defaultMessage="Without oil changes, tune ups, or any other combustion engine upkeep, EVs are much more affordable to maintain."
                  description="going second item content"
                />
              </p>
            </div>
            <div className="bottom small-space">
              <p className="bold">
                <FormattedMessage
                  id="learnMore.goingThreeTitle"
                  defaultMessage="Sticker Price Savings"
                  description="going third item title"
                />
              </p>
              <p>
                <FormattedMessage
                  id="learnMore.goingThreeContent"
                  defaultMessage="Between federal and local incentives and ever-increasing inventory of new and used EVs, electric vehicle  prices are lower than ever."
                  description="going third item content"
                />
              </p>
            </div>
          </div>
          <div className="col image right">
            <img src={bottomImage} alt="" />
          </div>
        </div>
      </section>
      <div className="row container flex-row-center">
        <div className="col text-center">
          <h5 className="frequently">
            <FormattedMessage
              id="learnMore.faqs"
              defaultMessage="EV 101 FAQS"
              description="FAQS title"
            />
          </h5>
        </div>
      </div>
      <section
        id="learnScroll"
        className={
          state.benefitsTab
            ? " container tab-section shadowed active"
            : " container tab-section shadowed"
        }
      >
        <div className="tab-header">
          <div
            className="container pointer"
            onClick={() => toggleTab("benefitsTab")}
          >
            <h1>
              <FormattedMessage
                id="learnMore.whatBenefits"
                defaultMessage="What are the benefits of driving an EV?"
                description="Benefits title"
              />
            </h1>
            {state.benefitsTab ? (
              <div className="toggle-button">
                <img src={upArrow} alt="Up" />
              </div>
            ) : (
              <div className="toggle-button">
                <img src={downArrow} alt="Down" />
              </div>
            )}
          </div>
        </div>
        <div className="tab-content">
          <FormattedMessage
            id="learnMore.whatBenefitsTitle"
            defaultMessage="Driving an electric vehicle provides multiple benefits, including:"
            description="Benefits title"
          />
          <ul className="content-list">
            <li>
              <FormattedMessage
                id="learnMore.whatBenefitsContentOne"
                defaultMessage="Lower maintenance costs"
                description="Benefits content"
              />
            </li>
            <li>
              <FormattedMessage
                id="learnMore.whatBenefitsContentTwo"
                defaultMessage="Lower fuel costs"
                description="Benefits content"
              />
            </li>
            <li>
              <FormattedMessage
                id="learnMore.whatBenefitsContentThree"
                defaultMessage="Federal and state tax credits"
                description="Benefits content"
              />
            </li>
            <li>
              <FormattedMessage
                id="learnMore.whatBenefitsContentFour"
                defaultMessage="Improves air quality"
                description="Benefits content"
              />
            </li>
          </ul>
        </div>
      </section>
      <section
        id="learnScroll"
        className={
          state.differenceTab
            ? " container tab-section shadowed active"
            : " container tab-section shadowed"
        }
      >
        <div className="tab-header">
          <div
            className="container pointer"
            onClick={() => toggleTab("differenceTab")}
          >
            <h1>
              <FormattedMessage
                id="learnMore.whatDifference"
                defaultMessage="What is the difference between a plug-in hybrid and an all-electric vehicle?"
                description="whatDifferent title"
              />
            </h1>
            {state.differenceTab ? (
              <div className="toggle-button">
                <img src={upArrow} alt="Up" />
              </div>
            ) : (
              <div className="toggle-button">
                <img src={downArrow} alt="Down" />
              </div>
            )}
          </div>
        </div>
        <div className="tab-content">
          <FormattedMessage
            id="learnMore.whatDifferenceOne"
            defaultMessage="A plug-in hybrid vehicle uses a gasoline engine to supplement the car’s range when the battery has been exhausted. An all-electricvehicle is powered solely by batteries that are recharged from anexternal source."
            description="whatDifferent content"
          />
        </div>
      </section>
      <section
        id="learnScroll"
        className={
          state.safeTab
            ? " container tab-section shadowed active"
            : " container tab-section shadowed"
        }
      >
        <div className="tab-header">
          <div
            className="container pointer"
            onClick={() => toggleTab("safeTab")}
          >
            <h1>
              <FormattedMessage
                id="learnMore.areSafe"
                defaultMessage="Are EVs as safe as gas-powered cars?"
                description="areSafe title"
              />
            </h1>
            {state.safeTab ? (
              <div className="toggle-button">
                <img src={upArrow} alt="Up" />
              </div>
            ) : (
              <div className="toggle-button">
                <img src={downArrow} alt="Down" />
              </div>
            )}
          </div>
        </div>
        <div className="tab-content">
          <FormattedMessage
            id="learnMore.areSafeOne"
            defaultMessage="Absolutely! All EVs sold in the United States must pass the same tests that gas-powered and hybrid cars do. In addition, EVs often require a heavier suspension to support the weight of batteries, which can also give EVs a lower center of gravity compared to a comparably sized gas-powered vehicle."
            description="areSafe content"
          />
        </div>
      </section>
      <section
        id="learnScroll"
        className={
          state.howTab
            ? " container tab-section shadowed active"
            : " container tab-section shadowed"
        }
      >
        <div className="tab-header">
          <div
            className="container pointer"
            onClick={() => toggleTab("howTab")}
          >
            <h1>
              <FormattedMessage
                id="learnMore.howDo"
                defaultMessage="How do EV maintenance costs compare to those of gas-powered vehicles?"
                description="howDo title"
              />
            </h1>
            {state.howTab ? (
              <div className="toggle-button">
                <img src={upArrow} alt="Up" />
              </div>
            ) : (
              <div className="toggle-button">
                <img src={downArrow} alt="Down" />
              </div>
            )}
          </div>
        </div>
        <div className="tab-content">
          <FormattedMessage
            id="learnMore.howDoOne"
            defaultMessage="Compared to gas-powered vehicles, electric vehicles have fewer moving
          parts. This translates into fewer components to maintain and replace
          over time. In EVs you don’t need to change oil, replace spark plugs
          and timing belts, or repair mufflers and catalytic converters.
          Furthermore, EVs employ regenerative breaking—a mechanism that allows
          the battery to capture the vehicle’s kinetic energy as it decelerates.
          Regenerative braking is enabled by the electric motor automatically
          slowing the vehicle after you remove your foot from the accelerator,
          which typically translates into longer brake lives compared to
          gas-powered vehicles."
            description="howDo content"
          />
        </div>
      </section>
      <section
        id="learnScroll"
        className={
          state.lifetimeTab
            ? " container tab-section shadowed active"
            : " container tab-section shadowed"
        }
      >
        <div className="tab-header">
          <div
            className="container pointer"
            onClick={() => toggleTab("lifetimeTab")}
          >
            <h1>
              <FormattedMessage
                id="learnMore.whatLifetime"
                defaultMessage="What is the expected lifetime for EV batteries?"
                description="howDo title"
              />
            </h1>
            {state.lifetimeTab ? (
              <div className="toggle-button">
                <img src={upArrow} alt="Up" />
              </div>
            ) : (
              <div className="toggle-button">
                <img src={downArrow} alt="Down" />
              </div>
            )}
          </div>
        </div>
        <div className="tab-content">
          <FormattedMessage
            id="learnMore.whatLifetimeOne"
            defaultMessage="EV battery warranties vary in length, but auto manufacturers are required by federal regulations to guarantee that batteries last at least eight years or 100,000 miles. In certain instances, you may be able to repair your EV battery instead of replacing it."
            description="howDo content"
          />
        </div>
      </section>
      <section className="bottom-footer ev-container-fluid white">
        <h1 className="bottom-header">
          <FormattedMessage
            id="learnMore.community"
            defaultMessage="Learn more about electric vehicle adoption and resources in your community"
            description="community title"
          />
        </h1>
        <div className="cbtns">{renderBtns}</div>
      </section>
    </section>
  );
};

export default FAQ;

FAQ.propTypes = {
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
