import React, { useEffect } from "react";
import PropTypes from "prop-types";
import IncentiveCatalog from "../../client_customizations/components/IncentiveCatalog/IncentiveCatalog";
import IncentiveSortControls from "../../client_customizations/components/IncentiveSortControls/IncentiveSortControls";
import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import { FormattedMessage } from "react-intl";
import IncentiveScoreOptions from "../../client_customizations/components/IncentiveScoreOptions/IncentiveScoreOptions";
import IncentiveVehicleOptions from "../../client_customizations/components/IncentiveVehicleOptions/IncentiveVehicleOptions";

import "./incentives.scss";

const Incentives = ({ electricVehicles, incentives, location, ip, uuid }) => {
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_INCENTIVES_TITLE;
  });

  const renderIncentives = incentives ? (
    <IncentiveCatalog incentives={incentives} category="all" />
  ) : (
    <LoadingSpinner />
  );
  const renderOptions = (
    <>
      <IncentiveScoreOptions />
      <br />
    </>
  );
  const renderVehicleOptions = electricVehicles ? (
    <IncentiveVehicleOptions electricVehicles={electricVehicles} />
  ) : null;
  return (
    <section className="ev-container-fluid" id="Incentives">
      <div className="row mb-3">
        <div className="col-sm-12 text-center">
          <h1 className="evHead">
            <FormattedMessage
              id="incentives.title"
              defaultMessage="Electric vehicle incentives"
              description="Electric vehicle incentives"
            />
          </h1>
          <p className="lead">
            <FormattedMessage
              id="evs.incentives.subTitle"
              defaultMessage="You may be eligible for a range of incentives, including rebates, tax credits {lineBreak} and more. Adjust the filters to personalize your results."
              description="Description of Platte River"
              values={{
                lineBreak: <br />,
              }}
            />
          </p>
          <div className="button-group" id="incentives">
                     <button className="btn btn-primary btn-lg active">
                                   <FormattedMessage
                                          id="incentives.residental.title"
                                          defaultMessage="Residential Incentives"
                                          description="Residential Incentives"
                                   />
                     </button>
            <a href="https://efficiencyworks.org/business/rebates/#EV" className="btn btn-primary btn-lg" role="button" target="_blank" rel="noopener noreferrer">
                     <FormattedMessage
                            id="incentives.commercial.title"
                            defaultMessage="Commercial Incentives"
                            description="Commercial Incentives"
                     />
            </a>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="pull-right">
            <IncentiveSortControls />
          </div>
        </div>
      </div>
      <div className="row main-container">
        <div className="col-xl-3 col-lg-4 d-block">
          {renderOptions}
          {renderVehicleOptions}
        </div>
        <div
          className="col-xl-9 col-lg-8 col-sm-12"
          style={{ paddingLeft: 10 }}
        >
          {renderIncentives}
        </div>
      </div>
    </section>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
